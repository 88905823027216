import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: [
        ],
      }),
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            position: "top"
          },
          title: {
            display: true,
            text: 'Huella de Carbono por año',
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            title: {
              display: true,
              text: 'tCO2e',
            },
          }],
        },
      }),
    },
  },
  mounted() {
    // Explicitly call the render method to apply options
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData(val) {
      // Ensure to destroy the existing chart instance before re-rendering
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }
      // Re-render the chart with the updated data and options
      this.renderChart(val, this.options);
    },
    options(newOptions) {
      // Watch for changes in options and re-render the chart
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }
      this.renderChart(this.chartData, newOptions);
    },
  },
};
